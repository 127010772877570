import api from '../../../utils/Api';

export const GET_GRANTS = 'grants/GET';
export const TOGGLE_SAVED_GRANT = 'savedGrant/TOGGLE';
export const REMOVE_SAVED_GRANT = 'savedGrant/REMOVE';
export const GET_SERVICE_FOCUS = 'grantServiceFocus/GET';
export const GET_TOTAL_GRANTS = 'totalGrants/GET_TOTAL';
export const GET_GWH_GRANT_FORM = 'grants/GET_GWH_GRANT_FORM';
export const GET_GWH_GRANT_SUBMISSION_PART2 = 'grants/GET_GWH_GRANT_SUBMISSION_PART2';
export const CLEAR_GRANTS = 'grants/CLEAR';
export const CLEAR_SERVICE_FOCUS = 'grantServiceFocus/CLEAR';
export const CLEAR_TOTAL_GRANTS = 'totalGrants/CLEAR';
export const APPLIED_GWH_GRANT_SUBMISSION = 'grants/APPLIED_GWH_GRANT_SUBMISSION';
export const GET_GRANT_APPLICATION_PART1 = 'grantApplicationPart1/GET';

const getGrantsAction = grantsData => {
  return {
    type: GET_GRANTS,
    grantsData,
  };
};

const toggleSavedGrantAction = grant => {
  return {
    type: TOGGLE_SAVED_GRANT,
    grant,
  };
};

const removeSavedGrantAction = grant => {
  return {
    type: REMOVE_SAVED_GRANT,
    grant,
  };
};

const getServiceFocusOptionsAction = serviceFocusOptions => {
  return {
    type: GET_SERVICE_FOCUS,
    serviceFocusOptions,
  };
};

const getTotalAmountGrantsAction = total => {
  return {
    type: GET_TOTAL_GRANTS,
    total,
  };
};

const getGWHGrantFormAction = form => {
  return {
    type: GET_GWH_GRANT_FORM,
    form,
  };
};

const getAppliedGWHGrantSubmissionAction = data => {
  return {
    type: APPLIED_GWH_GRANT_SUBMISSION,
    data,
  };
};

export const clearGrantsAction = () => {
  return {
    type: CLEAR_GRANTS,
  };
};

export const clearServiceFocusOptionsAction = () => {
  return {
    type: CLEAR_SERVICE_FOCUS,
  };
};

export const clearTotalAmountGrantsAction = () => {
  return {
    type: CLEAR_TOTAL_GRANTS,
  };
};

const getGWHGrantSubmissionPart2Action = data => {
  return {
    type: GET_GWH_GRANT_SUBMISSION_PART2,
    data,
  };
};

export const getGrantApplictionPart1Action = data => {
  return {
    type: GET_GRANT_APPLICATION_PART1,
    data,
  };
};

export const getMatchedGrantsThunk = params => async dispatch => {
  try {
    const response = await api.get(`grants/matched-grants/`, params);
    dispatch(getGrantsAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSavedGrantsThunk = params => async dispatch => {
  try {
    const response = await api.get(`grants/saved/`, params);
    dispatch(getGrantsAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getGWHGrantFormThunk = () => async dispatch => {
  try {
    const response = await api.getNonAuth(`grants/gwh-grant-form/`);
    dispatch(getGWHGrantFormAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getGWHGrantSubmissionPart2Thunk = submission => async dispatch => {
  try {
    const response = await api.getNonAuth(`grants/gwh-grant-submission-part2/`, { submission });
    dispatch(getGWHGrantSubmissionPart2Action(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAppliedGWHGrantSubmissionThunk = () => async dispatch => {
  try {
    const response = await api.get(`grants/applied-gwh-grant-submission/`);
    dispatch(getAppliedGWHGrantSubmissionAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateMatchedGrantsThunk = payload => async dispatch => {
  try {
    const response = await api.post('grants/matched-grants/', payload);
    if (response.ok) {
      return response;
    }
    throw new Error('Something went wrong with fetching matched grants. Please try again.');
  } catch (error) {
    throw error;
  }
};

export const saveGrantThunk = payload => async dispatch => {
  try {
    const response = await api.post(`grants/saved/`, payload);
    dispatch(toggleSavedGrantAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const unsaveGrantThunk = (params, path) => async dispatch => {
  try {
    const response = await api.delete(`grants/saved/`, params);
    if (path.includes('matched')) dispatch(toggleSavedGrantAction(response.data));
    else if (path.includes('bookmarked-grants')) dispatch(removeSavedGrantAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getServiceFocusOptionsThunk = () => async dispatch => {
  try {
    const response = await api.getNonAuth(`grants/service-focus/`);
    dispatch(getServiceFocusOptionsAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTotalAmountGrantsThunk = () => async dispatch => {
  try {
    const response = await api.get('grants/total/');
    if (response.ok) dispatch(getTotalAmountGrantsAction(response.data.total_amount_grants));
  } catch (error) {
    throw error;
  }
};

export const GWHGrantFormSubmissionThunk = payload => async () => {
  try {
    const response = await api.postNonAuth(`grants/gwh-grant-submission/`, payload);
    if (response.ok) return response;
  } catch (error) {
    throw error;
  }
};

export const GWHGrantSubmissionPart2Thunk = payload => async () => {
  try {
    const response = await api.postNonAuth(`grants/gwh-grant-submission-part2/`, payload);
    if (response.ok) return response;
  } catch (error) {
    throw error;
  }
};

export const getGrantApplicationPart1Thunk = submissionCode => async dispatch => {
  const response = await api.getNonAuth(`grants/gwh-grant-submission/?submission=${submissionCode}`);
  dispatch(getGrantApplictionPart1Action(response.data));
  return response;
};

export const createOrEditGrantApplictionPart1Thunk = payload => async dispatch => {
  try {
    const response = await api.postNonAuth(`grants/gwh-grant-submission/`, payload);
    if (response.ok) return response;
  } catch (error) {
    throw error;
  }
};

export const GWHGrantSubmissionPart3Thunk = payload => async () => {
  try {
    const response = await api.postNonAuth(`grants/gwh-grant-submission-part3/`, payload);
    if (response.ok) return response;
  } catch (error) {
    throw error;
  }
};
